import React from 'react';

import timelineData from '../../helpers/timelineData';

const sortedTimelineData = [...timelineData]
	.reverse()
	.sort((a, b) => new Date(b.date) - new Date(a.date));

const TimelineItem = ({ data }) => (
	<div className='timeline-item'>
		<div className='timeline-item-content'>
			<span className='tag' style={{ background: data.category.color }}>
				{data.category.tag}
			</span>
			<time>{data.date}</time>
			<p>{data.text}</p>
			{data.link && (
				<a href={data.link.url} target='_blank' rel='noopener noreferrer'>
					{data.link.text}
				</a>
			)}
			<span className='circle' />
		</div>
	</div>
);

const Timeline = () =>
	sortedTimelineData.length > 0 && (
		<div className='timeline-container'>
			{sortedTimelineData.map((data, idx) => (
				<TimelineItem data={data} key={idx} />
			))}
		</div>
	);

export default Timeline;
