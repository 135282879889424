import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Other/seo';
import Timeline from '../components/Other/Timeline';

const TimelinePage = () => (
	<Layout>
		<div className='center-container'>
			<div className='row middle-xs container-full'>
				<div className='col-xs-12 text-center'>
					<SEO
						title='Florin Pop - Timeline'
						description='A timeline of my professional accomplishments'
					/>
					<br />
					<h1>My road to glory!</h1>
					<p>
						Below you can see a timeline of my professional accomplishments.{' '}
						<br />I hope that this will motivate you to follow your dreams and
						to write down your own accomplishments along the way!
					</p>
					<Timeline />
					<p style={{ marginBottom: '15px' }}>
						This page is updated regularly and you are welcomed to come back and
						check my progress!
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default TimelinePage;
