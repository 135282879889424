// Add one of the categories below
const CATEGORIES = {
    APP_IDEAS: {
        tag: "app-ideas",
        color: "#FFDB14",
    },
    TWITTER: {
        tag: "twitter",
        color: "#1DA1F2",
    },
    MEDIUM: {
        tag: "medium",
        color: "#018f69",
    },
    BLOG: {
        tag: "blog",
        color: "#e17b77",
    },
    DEVTO: {
        tag: "dev.to",
        color: "#0a0a0a",
    },
    PATREON: {
        tag: "patreon",
        color: "#E85B46",
    },
    TWITCH: {
        tag: "twitch",
        color: "#6441a5",
    },
    YOUTUBE: {
        tag: "youtube",
        color: "#ff0000",
    },
    FACEBOOK: {
        tag: "facebook",
        color: "#3b5998",
    },
    CHALLENGE_100D_100P: {
        tag: "100 days - 100 projects",
        color: "#8C51FF",
    },
    EBOOK: {
        tag: "eBook",
        color: "rebeccapurple",
    },
    OTHER: {
        tag: "other",
        color: "#777",
    },
};

/* Default object model for a new timeline data:
{
    text: '',
    date: '',
    category: ,
    link: {
        url: '',
        text: ''
    }
},
*/

// Date format example: April 09 2019

export default [
    {
        text: "Wrote my first blog post ever on Medium",
        date: "March 03 2017",
        category: CATEGORIES.MEDIUM,
        link: {
            url:
                "https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2",
            text: "Read more",
        },
    },
    {
        text: "Created my blog and wrote my first blog post on it",
        date: "August 20 2017",
        category: CATEGORIES.BLOG,
        link: {
            url: "https://florin-pop.com/blog/2017/08/hello-world/",
            text: "Read more",
        },
    },
    {
        text: "Started working on the app-ideas repository",
        date: "February 25 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "Jim joined as a main contributor on the app-ideas repository",
        date: "March 01 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check the project out on GitHub",
        },
    },
    {
        text:
            'Started the Weekly Coding Challenge program. Wrote week #1 post about a "Double Slider Sign in/up Form"',
        date: "March 04 2019",
        category: CATEGORIES.BLOG,
        link: {
            url:
                "https://www.florin-pop.com/blog/2019/03/double-slider-sign-in-up-form/",
            text: "Check it out here",
        },
    },
    {
        text: "First time seeing 20+ people on my blog at the same time",
        date: "March 05 2019",
        category: CATEGORIES.BLOG,
    },
    {
        text: "Wrote the Weekly Coding Challenge Complete Guide",
        date: "March 06 2019",
        category: CATEGORIES.BLOG,
        link: {
            url: "https://florin-pop/blog/2019/03/weekly-coding-challenge/",
            text: "Check it out here",
        },
    },
    {
        text: "1.000 followers on Twitter",
        date: "March 07 2019",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "100 email subscribers in a day",
        date: "March 07 2019",
        category: CATEGORIES.BLOG,
    },
    {
        text: "Wrote my first blog post on Dev.to",
        date: "March 13 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url:
                "https://dev.to/florinpop17/weekly-coding-challenge---the-complete-guide-25om",
            text: "Check it out here",
        },
    },
    {
        text:
            "A publication offered to pay me to write a blog post on their website for the first time",
        date: "March 17 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text:
            "I published my first article in the FreeCodeCamp Medium Publication",
        date: "March 18 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url:
                "https://medium.freecodecamp.org/how-to-build-a-double-slider-sign-in-and-sign-up-form-6a5d03612a34",
            text: "Check it out here",
        },
    },
    {
        text: "Over 1.000 views in a single day on my Medium posts",
        date: "March 19 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text: "Over 2.000 views in a single day on my Medium posts",
        date: "March 22 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text:
            'Adrian made a Youtube video in which he showcased my "Double Slider Sign in/up Form" project',
        date: "March 22 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://www.youtube.com/watch?v=4dcSTPPk4YM",
            text: "Check out the video",
        },
    },
    {
        text:
            "Had a skype call with someone and I prepared him for a React job interview",
        date: "March 25 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "Over 3.000 views in a single day on my Medium posts",
        date: "March 28 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text: "1.000 followers on dev.to",
        date: "March 29 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url: "https://dev.to/florinpop17",
            text: "See profile",
        },
    },
    {
        text:
            'Published post: "Here are some app ideas you can build to level up your coding skills" on FCC Medium Publication. This post was received very well by the community!',
        date: "April 04 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url:
                "https://medium.freecodecamp.org/here-are-some-app-ideas-you-can-build-to-level-up-your-coding-skills-39618291f672",
            text: "Read article",
        },
    },
    {
        text: "Over 12.000 views in a single day on my Medium posts",
        date: "April 05 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text: "Gained 1.000 stars on app-ideas repository",
        date: "April 07 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "2.000 followers on Twitter",
        date: "April 09 2019",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "Gained 2.000 stars on app-ideas repository",
        date: "April 09 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "1.000 followers on Medium",
        date: "April 15 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text: "Added a total of 50 projects in the app-ideas repository",
        date: "April 15 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text:
            '50.000 total views on: "Here are some app ideas you can build to level up your coding skills"',
        date: "April 15 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url:
                "https://medium.freecodecamp.org/here-are-some-app-ideas-you-can-build-to-level-up-your-coding-skills-39618291f672",
            text: "Check out the article",
        },
    },
    {
        text:
            "100.000 total views on Medium over the last 30 days (first time)",
        date: "April 16 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text:
            "Brad Traversy posted a video on YouTube about the Sign in/up form",
        date: "April 17 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/watch?v=mUdo6w87rh4",
            text: "Check the video",
        },
    },
    {
        text: "Gained 3.000 stars on app-ideas repository",
        date: "April 18 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "2.000 followers on dev.to",
        date: "April 22 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url: "https://dev.to/florinpop17",
            text: "See profile",
        },
    },
    {
        text: "Became a Top Writer in Technology on Medium",
        date: "April 27 2019",
        category: CATEGORIES.MEDIUM,
        link: {
            url: "https://medium.com/@popflorin1705",
            text: "See profile",
        },
    },
    {
        text: "Started earning from my blog via CarbonAds",
        date: "May 9 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "First sponsored blog post",
        date: "May 13 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "3.000 followers on Twitter",
        date: "May 15 2019",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "Blog post was published in the React Status issue #138",
        date: "May 15 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://react.statuscode.com/issues/138",
            text: "Check out the issue",
        },
    },
    {
        text: "Officially launched my Patreon page",
        date: "May 17 2019",
        category: CATEGORIES.PATREON,
        link: {
            url: "https://www.patreon.com/florinpop17",
            text: "Become a Patron",
        },
    },
    {
        text: "Swishyfishie became my first ever Patron",
        date: "May 17 2019",
        category: CATEGORIES.PATREON,
        link: {
            url: "https://twitter.com/Swishyfishie2",
            text: "Twitter profile",
        },
    },
    {
        text: "First paid blog post",
        date: "May 21 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "Launched the Codavengers team",
        date: "May 21 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "Gained 5.000 stars on app-ideas repository",
        date: "May 29 2019",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "Recorded my first screencast",
        date: "June 09 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://scrimba.com/c/cemq7zSP",
            text: "Check it out on Scrimba",
        },
    },
    {
        text: "3.000 followers on dev.to",
        date: "June 13 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url: "https://dev.to/florinpop17",
            text: "See profile",
        },
    },
    {
        text: "Joined Upriserz - The Best Romanian Business platform",
        date: "June 17 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://store.upriserz.ro/referral/5d028324e5d14",
            text: "Check out the platform",
        },
    },
    {
        text: "Started doing Zoom meetings to help the community",
        date: "July 01 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://calendly.com/florinpop",
            text: "Schedule a call via Calendly",
        },
    },
    {
        text:
            "Quit my job and started working full-time on my blog and side projects",
        date: "July 05 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "5.000 followers on Twitter",
        date: "July 10 2019",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "Created a Facebook page",
        date: "July 10 2019",
        category: CATEGORIES.FACEBOOK,
        link: {
            url: "https://facebook.com/florinpop17",
            text: "See page",
        },
    },
    {
        text: "Did my first Live Stream on Twitch",
        date: "July 11 2019",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text: "Got first donation after a 1-on-1 Zoom meeting (Meeting #15)",
        date: "July 18 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "Got approved by the first affiliated product",
        date: "July 18 2019",
        category: CATEGORIES.OTHER,
    },
    {
        text: "Launched a SPECIAL discord server for patrons",
        date: "Jul 23 2019",
        category: CATEGORIES.PATREON,
        link: {
            url: "https://patreon.com/florinpop17",
            text: "You can too join here",
        },
    },
    {
        text: "Awarded the Top 7 Badge on Dev.to",
        date: "Aug 06 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url: "https://dev.to/florinpop17",
            text: "See profile",
        },
    },
    {
        text: "Started the #100Days100Projects Challenge",
        date: "Sep 17 2019",
        category: CATEGORIES.CHALLENGE_100D_100P,
        link: {
            url: "https://florin-pop.com/blog/2019/09/100-days-100-projects",
            text: "Check out the project here",
        },
    },
    {
        text: "Restarted Streaming on Twitch",
        date: "Sep 29 2019",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text: "50 followers on Twitch",
        date: "Oct 02 2019",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text: "Reached $100+ on Patreon",
        date: "Oct 05 2019",
        category: CATEGORIES.PATREON,
        link: {
            url: "https://patreon.com/florinpop17",
            text: "You can join too here",
        },
    },
    {
        text: "#100Days100Projects Challenge paid out - BIG TIME!",
        date: "Oct 08 2019",
        category: CATEGORIES.CHALLENGE_100D_100P,
        link: {
            url: "https://florin-pop.com/blog/2019/09/100-days-100-projects",
            text: "Check out the project here",
        },
    },
    {
        text: "Became a Twitch Affiliate",
        date: "Oct 16 2019",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text: "100 followers on Twitch",
        date: "Oct 16 2019",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text:
            "Brad Traversy posted another YouTube video of one of my projects",
        date: "Oct 21 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/watch?v=duNmhKgtcsI",
            text: "Check the video",
        },
    },
    {
        text: "Created my first YouTube video! 🤩",
        date: "Nov 7 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/watch?v=NpPrEWdnofU",
            text: "Check it out",
        },
    },
    {
        text:
            "100 subscribers on YouTube in less than 3 hours after I published my first video! 😱",
        date: "Nov 7 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "200 subscribers on YouTube in less than a day! 😱",
        date: "Nov 8 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "500 subscribers on YouTube!",
        date: "Nov 14 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "First sale (affiliate) while sleeping. Dream come true!",
        date: "Nov 26 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://www.creative-tim.com/?partner=120383",
            text: "Creative-Tim",
        },
    },
    {
        text:
            "Made my first $200 promoting the JavaScript for Beginner Course by Wes Bos",
        date: "Nov 28 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://beginnerjavascript.com/friend/FLORINPOP",
            text: "Get your copy here",
        },
    },
    {
        text: "1000 subscribers on YouTube (in less than a month)!",
        date: "Dec 06 2019",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "Awarded Top 500 contributors of 2019 for Dev.to",
        date: "Dec 07 2019",
        category: CATEGORIES.DEVTO,
        link: {
            url: "https://dev.to/florinpop17",
            text: "See profile",
        },
    },
    {
        text: "10.000 followers on Twitter",
        date: "Dec 09 2019",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: 'Won "The most active user in 2019" award on Upriserz 🤩',
        date: "Dec 10 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://store.upriserz.ro/referral/5d028324e5d14",
            text: "Check out the platform",
        },
    },
    {
        text: "Made it to top 12 most liked projects on CodePen in 2019",
        date: "Dec 22 2019",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://codepen.io/2019/popular/pens/9",
            text: "Check out the top here",
        },
    },
    {
        text: "Finished the #100Days100Projects Challenge",
        date: "Dec 25 2019",
        category: CATEGORIES.CHALLENGE_100D_100P,
        link: {
            url: "https://florin-pop.com/blog/built-100-projects-in-100-days",
            text: "Read about my experience here",
        },
    },
    {
        text: "Awarded Top 100 contributors of 2019 for FreeCodeCamp",
        date: "Dec 26 2019",
        category: CATEGORIES.OTHER,
        link: {
            url:
                "https://www.freecodecamp.org/news/fcc100-top-contributors-2019/",
            text: "Check it out here",
        },
    },
    {
        text: "2000 subscribers on YouTube",
        date: "Jan 02 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "3000 subscribers on YouTube",
        date: "Jan 11 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "50,000 total views on YouTube videos",
        date: "Jan 23 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "5000 subscribers on YouTube",
        date: "Jan 25 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "Gained 10,000 stars on app-ideas repository",
        date: "February 13 2020",
        category: CATEGORIES.APP_IDEAS,
        link: {
            url: "https://github.com/florinpop17/app-ideas",
            text: "Check it out on GitHub",
        },
    },
    {
        text: "New challenge: Conquering freeCodeCamp's Curriculum",
        date: "February 16 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url:
                "https://www.youtube.com/playlist?list=PLgBH1CvjOA62oNEVgz-dECiCZCE_Q3ZFH",
            text: "Check it out",
        },
    },
    {
        text:
            "I was finally able to apply for Monetisation - first big goal of 2020",
        date: "February 17 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/watch?v=klGFw-UkuMQ",
            text: "Check out the video",
        },
    },
    {
        text: "10,000 subscribers on YouTube",
        date: "March 12 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "Completed the entire freeCodeCamp Curriculum in a Month",
        date: "March 16 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url:
                "https://www.youtube.com/playlist?list=PLgBH1CvjOA62oNEVgz-dECiCZCE_Q3ZFH",
            text: "Check it out",
        },
    },
    {
        text:
            "Got the biggest donation ever in a Live Stream ($100 from Dennis)",
        date: "April 5 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url:
                "https://www.youtube.com/watch?v=kNCJImEEZ5M&feature=youtu.be&t=3325",
            text: "Check out the moment",
        },
    },
    {
        text: "Streamed for the first time on freeCodeCamp's YouTube channel",
        date: "May 3 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/watch?v=WjUdCdfJSMs",
            text: "Check our the Stream",
        },
    },
    {
        text: "30,000 followers on Twitter",
        date: "May 3 2020",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "20,000 subscribers on YouTube",
        date: "May 6 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "Restarted streaming on Twitch",
        date: "June 10 2020",
        category: CATEGORIES.TWITCH,
        link: {
            url: "https://twitch.com/florinpop17",
            text: "Follow me",
        },
    },
    {
        text: "50,000 followers on Twitter",
        date: "July 31 2020",
        category: CATEGORIES.TWITTER,
        link: {
            url: "https://twitter.com/florinpop1705",
            text: "See profile",
        },
    },
    {
        text: "Joined the Traversy Media team",
        date: "August 5 2020",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://twitter.com/traversymedia/status/1291018193662889986",
            text: "Check out the announcement tweet",
        },
    },
    {
        text:
            "Interviewed for the first time in a Podcast about my Journey as a Content Creator",
        date: "August 14 2020",
        category: CATEGORIES.OTHER,
        link: {
            url: "https://www.jsleague.ro/jsleague-show/episode-18",
            text: "Listen to the podcast",
        },
    },
    {
        text: "30,000 subscribers on YouTube",
        date: "August 16 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text:
            'Started pre-selling my eBook: "Ten++ Ways to Make Money as a Developer"',
        date: "August 21 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
    {
        text:
            'Did $1000 pre-selling the eBook "Ten++ Ways to Make Money as a Developer"',
        date: "August 22 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
    {
        text:
            'Did $3000 pre-selling my eBook "Ten++ Ways to Make Money as a Developer"',
        date: "August 23 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
    {
        text: "50,000 subscribers on YouTube",
        date: "September 16 2020",
        category: CATEGORIES.YOUTUBE,
        link: {
            url: "https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA",
            text: "Check out my channel",
        },
    },
    {
        text: "Launched the #50Projects50Days course - HTML, CSS, JavaScript",
        date: "December 8 2020",
        category: CATEGORIES.OTHER,
        link: {
            url:
                "https://www.udemy.com/course/50-projects-50-days/?couponCode=30DAYPROMO",
            text: "Check it out on Udemy",
        },
    },
    {
        text: 'Launched the "Ten++ Ways to Make Money as a Developer" eBook',
        date: "December 14 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
    {
        text:
            'Did $5000 total selling my eBook "Ten++ Ways to Make Money as a Developer"',
        date: "December 14 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
    {
        text:
            '$10,000 revenue in a week selling my eBook "Ten++ Ways to Make Money as a Developer"',
        date: "December 21 2020",
        category: CATEGORIES.EBOOK,
        link: {
            url: "https://gum.co/makemoneydev",
            text: "Check it out",
        },
    },
];
